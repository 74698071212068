.main {
  margin: 0;
  padding: 5px;
  background-color: #d3d3d3;
}
.signal {
  margin: 5px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #000;
  background-color: gray;
}
.driveritem {
  margin: 5px !important;
  padding: 15px;
}
.catitem {
  margin: 3px !important;
}
.proditem {
  margin: 1px !important;
  height: 100px;
}
.imgitem {
  width: 200px;
  height: 200px;
}
.containeritem {
  text-align: center;
}
.searchbox {
  width: 500px;
  margin-left: 25px !important;
}
.submenu {
  display: flex;
  align-items: center;
}
.carting {
  font-size: 50px !important;
  color: #00f;
  padding-left: 50px;
}
.cartingadd {
  font-size: 30px !important;
  color: #00f;
  padding-right: 10px;
}
.cartingadd:active {
  color: #adff2f;
}
